@font-face {
    font-family: "Sul Sans";
    src: url('../assets/fonts/sul-sans/regular.woff2') format("woff2"), url('../assets/fonts/sul-sans/regular.woff2') format("woff");
    /*src: url('./fonts/sul-sans/regular.woff2') format("woff2"), url('./fonts/sul-sans/regular.woff2') format("woff");*/
    font-weight: 400;
    font-display: fallback;
}
@font-face {
    font-family: "Sul Sans";
    src: url('../assets/fonts/sul-sans/light.woff2') format("woff2"), url('../assets/fonts/sul-sans/light.woff2') format("woff");
    /*src: url('./fonts/sul-sans/light.woff2') format("woff2"), url('./fonts/sul-sans/light.woff2') format("woff");*/
    font-weight: 300;
    font-display: fallback;
}
@font-face {
    font-family: "Sul Sans";
    src: url('../assets/fonts/sul-sans/medium.woff2') format("woff2"), url('../assets/fonts/sul-sans/medium.woff2') format("woff");
    /*src: url('./fonts/sul-sans/medium.woff2') format("woff2"), url('./fonts/sul-sans/medium.woff2') format("woff");*/
    font-weight: 600;
    font-display: fallback;
}
@font-face {
    font-family: "Sul Sans";
    src: url('../assets/fonts/sul-sans/bold.woff2') format("woff2"), url('../assets/fonts/sul-sans/bold.woff2') format("woff");
    /*src: url('./fonts/sul-sans/bold.woff2') format("woff2"), url('./fonts/sul-sans/bold.woff2') format("woff");*/
    font-weight: 700;
    font-display: fallback;
}
@font-face {
    font-family: "Sul Sans";
    src: url('../assets/fonts/sul-sans/black.woff2') format("woff2"), url('../assets/fonts/sul-sans/black.woff2') format("woff");
    /*src: url('./fonts/sul-sans/black.woff2') format("woff2"), url('./fonts/sul-sans/black.woff2') format("woff");*/
    font-weight: 800;
    font-display: fallback;
}
html {
    position: fixed;
    top: 0;
}
html, body {
    overflow: hidden;
}
.Theme-wrapper {
    background: rgba(255, 255, 255, 1.0);
    height: 100vh;
}
.Theme-override-wrapper {
    background: rgba(255, 255, 255, 1.0);
    height: 100vh;
}
h1 {
    font-weight: 700;
    font-style: normal;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: 0;
}
h2 {
    font-weight: 700;
    font-style: normal;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: 0;
}
h3 {
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0;
}
sup {
    top: -1.25em;
}
sub, sup {
    font-size: 35%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
#modal-window button{
    background-color: rgba(255, 255, 255, 1);
}
.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 32px;
}
.react-pdf__Page__textContent.textLayer {
    display: none;
}
.annotationLayer {
    display: none;
}
/*Welcome Wizard*/
#WelcomeWizard{
    position: relative;
}
.welcomeWizardMainOverrides  {
    background-color: rgba(255, 255, 255, 1) !important;
}
.welcomeWizardMainOverrides nav {
    background-color: rgba(255, 255, 255, 1) !important;
}
.welcomeWizardMainOverrides main {
    background-color: rgba(88, 89, 91, 0.9) !important;
}
.welcomeWizardMainOverrides {
    background-color: rgba(69, 69, 71, 1) !important;
    margin-bottom: 16px;
}
#welcome-wizard-footer {
    background-color: rgba(88, 89, 91, 1) !important;
}
